import './App.css';
import { useEffect } from 'react';
import { Hub, Auth } from 'aws-amplify';
import Dashboard from './comps/Dashboard';
import DeviceContainer from './comps/devices/DeviceContainer';
import DeviceRegisterContainer from './comps/devices/DeviceRegisterContainer';
import PatientsContainer from './comps/patients/PatientsContainer';
import RedirectLogin from './comps/RedirectLogin';
import ExamsContainer from './comps/patients/ExamsContainer';
import { Routes, Route, Navigate , useNavigate} from 'react-router-dom';
import PrintContainer from './comps/patients/PrintContainer';

function App() {

  const nav = useNavigate();

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          console.log('Authenticated...');
          console.log(event, data);
          nav("/dashboard");
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Error', data);
          break;
        default:
          console.log(event, data);
          break;
      }
    });
  }, [nav]);

  const client_id = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const login = window.localStorage.getItem(`CognitoIdentityServiceProvider.${client_id}.LastAuthUser`);

  const handleOnSubmit = (): void => {
    Auth.federatedSignIn();
  }

  return (
    <>      
      <Routes>
        <Route  path="/" element={ login !== null ? <Navigate  to="/dashboard"/> : <RedirectLogin onClick={ handleOnSubmit } />} />
        <Route path="/dashboard" element={ login == null ? <Navigate  to="/"/> : <Dashboard />} />
        <Route path="/devices" element={ login == null ? <Navigate  to="/"/> : <DeviceContainer />} />             
        <Route path="/patients" element={ login == null ? <Navigate  to="/"/> : <PatientsContainer />} />             
        <Route path="/patients/:pk/:sk" element={ login == null ? <Navigate  to="/"/> : <ExamsContainer />} />             
        <Route path="/devices/register" element={ login == null ? <Navigate  to="/"/> : <DeviceRegisterContainer />} />             
        <Route path="/exam/:pk/:sk" element={ login == null ? <Navigate  to="/"/> : <PrintContainer />} />             
      </Routes>
    </>
  );
}

export default App;
