import { DataChart, ExamsPatientTableRow, ExamsTable, ExamTableRow } from "../types";

/**
 * Calculate BMI
 */
const calculateBMI = (feet: number, inches: number, weigthLb: number): number => {
    let feetToInches = feet * 12;
    feetToInches = feetToInches + (inches * 1);
    const bmi = (weigthLb / (feetToInches ** 2)) * 703;   
    return Math.round(bmi * 100) / 100 
}

const ExamsReturnPatient = (data: ExamsTable): ExamsPatientTableRow => {    
    const firstR = data.Items[0];
    const Patient: ExamsPatientTableRow = {
        name: firstR.PATIENT_INFORMATION.name[0].text,
        gender: firstR.PATIENT_INFORMATION.gender,
        bmi: calculateBMI(firstR.PATIENT_CORE_BODY.height.feet, firstR.PATIENT_CORE_BODY.height.inches, firstR.PATIENT_CORE_BODY.weight),
        pain: firstR.EXAM_DATA.painScale,
        date_birth: firstR.PATIENT_INFORMATION.birthDate,
        weight:  firstR.PATIENT_CORE_BODY.weight.toString() + "lb",
        height:  firstR.PATIENT_CORE_BODY.height.feet.toString() + "'" + firstR.PATIENT_CORE_BODY.height.inches.toPrecision(1).toString() + "\""
    }
    return Patient;
}

const ExamsReturnExams = (data: ExamsTable): Array<ExamTableRow> => {
    return data.Items.map((e) => {
        return {
            ExamData: e.EXAM_DATA,
            AUDIO_URL: e.AUDIO_URL,
            SENSOR_URL: e.SENSOR_URL,
            "USER_ID#DEVICE_ID": e["USER_ID#DEVICE_ID"],
            "PATIENT_FULL_NAME#DATE_TIME": e["PATIENT_FULL_NAME#DATE_TIME"]
        }
    });
}

const ExamsReturnDataChart = (data: ExamsTable) : DataChart  => {
    const orderLabels = data.Items.map((e) => {
        return e.EXAM_DATA.date.substring(0, 16)
    });
    const dataSetRigth: Array<number> = [];
    const dataSetLeft: Array<number> = [];
    data.Items.forEach((e) => {
        if (e.EXAM_DATA.knee === 'rigth') {
            dataSetRigth.push(e.EXAM_DATA.score);
            dataSetLeft.push(0);

        } else if (e.EXAM_DATA.knee === 'left') {
            dataSetLeft.push(e.EXAM_DATA.score);
            dataSetRigth.push(0);
        }
    });
    return {
        labels: orderLabels,
        datasets: [
            {
                label: 'Left',
                data: dataSetLeft,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Right',
                data: dataSetRigth,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
        ]
    };
}
 
export { calculateBMI, ExamsReturnPatient, ExamsReturnExams, ExamsReturnDataChart }