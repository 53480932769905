import { NavLink } from "react-router-dom";

interface Props {
    icon: string
    to: string
    title: string
}

export default function  MenuItem(props: Props) {
    let className = "mdi " + props.icon;
    return (
        <li>
            <NavLink to={props.to}>
                <figure className="image is-16x16 is-inline-block mr-2">
                    <span className={className}></span>
                </figure>
                {props.title}
            </NavLink>
        </li>
    );
} 