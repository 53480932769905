import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  title: string
  to: string
}
/**
 * Draw Box Img Link in List Boxes
 */
export default function BoxImg(props: Props): JSX.Element {
  return (
    <>
      <NavLink to={props.to}>
        <div className="column is-offset-1">
          <div className="has-text-centered-desktop is-size-2-mobile is-flex-touch is-justify-content-space-between">
            <svg width="100"  viewBox="0 0 178 167" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_2349_3750)">
                <path d="M152.643 4H25.3571C15.7746 4 8 11.2142 8 20.1062V138.218C8 147.11 15.7746 154.324 25.3571 154.324H152.643C162.225 154.324 170 147.11 170 138.218V20.1062C170 11.2142 162.225 4 152.643 4ZM141.071 88.5573C141.071 90.7719 139.119 92.5838 136.732 92.5838H103.464V123.454C103.464 125.669 101.512 127.48 99.125 127.48H78.875C76.4884 127.48 74.5357 125.669 74.5357 123.454V92.5838H41.2679C38.8812 92.5838 36.9286 90.7719 36.9286 88.5573V69.7668C36.9286 67.5522 38.8812 65.7402 41.2679 65.7402H74.5357V34.8701C74.5357 32.6555 76.4884 30.8436 78.875 30.8436H99.125C101.512 30.8436 103.464 32.6555 103.464 34.8701V65.7402H136.732C139.119 65.7402 141.071 67.5522 141.071 69.7668V88.5573Z" fill="#CFE220" />
              </g>
              <defs>
                <filter id="filter0_d_2349_3750" x="0" y="0" width="178" height="166.324" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="4" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.742872 0 0 0 0 0.8125 0 0 0 0 0.101562 0 0 0 0.68 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2349_3750" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2349_3750" result="shape" />
                </filter>
              </defs>
            </svg>
            <p className="is-size-7 is-inline-block-mobile is-text-right-mobile">{props.title}</p>
          </div>
        </div>
      </NavLink>
    </>
  )
}