import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatientPayload, PatientState, PatientStateExams } from '../../types';

const initialState: PatientState = {
  Patients: {
    Items: [],
    Count: 0,
    ScannedCount: 0
  }, 
  PatientTableData: [],
  Exams: {
    Patient: {
      gender: "",
      name: "",
      date_birth: "",
      bmi: 0,
      pain: 0,
      weight: "",
      height: ""
    },
    ExamsList: [],
    ExamsChart: {
      labels: [],
      datasets: []
    }
  }

}

export const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setPatients(state, action: PayloadAction<PatientPayload>) {
      state.Patients = action.payload.data;
      state.PatientTableData = action.payload.tableData;
    }, 
    setExams(state, action: PayloadAction<PatientStateExams>) {
        state.Exams = action.payload;
    }   
  },
});

// Action creators are generated for each case reducer function
export const { setPatients, setExams } = patientsSlice.actions

export default patientsSlice.reducer