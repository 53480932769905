import React, { useState } from "react";
import Header from '../Header';

interface Props {
    onSubmit(code:string): void
}

export default function DeviceFormView(props : Props) {

    const [code, setCode] = useState("");

    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        props.onSubmit(code);
        event.preventDefault();
    }

    return (
        <div className="columns">
            <Header />
            <div className="column px-6 pt-6 ">
                <form onSubmit={handleOnSubmit}>
                    <div className="columns mt-3">
                        <div className="column is-half">
                            <h1 className="mt-6">
                                <span className="big-green">ADD</span>
                                DEVICE
                            </h1>
                            <div className="has-text-centered mt-3">
                                <h3 className="t35 t-db">Enter Code</h3>
                                <p className="t-db">Enter the code that appears on your device</p>
                                <div className="field">
                                    <p className="control has-icons-left">
                                        <input className="input-code has-text-centered" onChange={event => setCode(event.target.value)} type="text" />
                                    </p>
                                </div>
                                <button
                                    className="button is-success is-fullwidth is-large kv-bt-text-blue"
                                >
                                    ADD DEVICE
                                </button>
                            </div>
                        </div>
                        <div className="column  is-half has-text-centered">
                            <section className="hero is-medium mt-6">
                                <h6 className="t20 t-b">IMPORTANT!</h6>
                                <p><small>
                                    The code is located on your device created during the registration process.
                                </small></p>
                                <img src="/pngs/register.png" alt="" style={{ height: 493, width: '297px', margin: 'auto' }} />
                            </section>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}