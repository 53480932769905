import ListBoxes from "../utils/ListBoxes";
import Box from "../utils/Box";
import BoxImg from "../utils/BoxImg";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getDevices, getCounts, getUserId } from "../../app/Api";
import { setUserInfo, setDevices } from "../features/UserSlice";
import { setCounts } from "../features/CountsSlice";
import { CountsInfo, DeviceInfo } from '../../types';
import { Auth } from "aws-amplify";

/**
 * Container Device info
 * @returns {String} JSX Template 
 */
export default function DeviceContainer(): JSX.Element {
    const dispatch = useAppDispatch();
    const deviceInfo = useAppSelector((state) => {
        return state.user.deviceInfo;
    });
    const countsInfo = useAppSelector((state) => {
        return state.counts.countsInfo;
    });

    const nav = useNavigate();

    useEffect(() => {
        if (deviceInfo.Count === 0) {
            const sub = getUserId();
            dispatch(setUserInfo({ sub }));
            getDevices().then((response: any) => {
                const data: DeviceInfo = response.data;
                dispatch(setDevices(data));
            }).catch((err) => {
                if (err.response.data.message === "The incoming token has expired") {
                    Auth.signOut();
                    nav('/');
                } else {
                    console.log(err)
                }
            });
            getCounts().then((response: any) => {
                const data: CountsInfo = response.data;
                dispatch(setCounts(data));
            });
        }
    }, [dispatch, nav, deviceInfo.Count]);

    return (
        <div className="columns">
            <Header />
            <div className="column  px-6 pt-6 ">
                <h1>DEVICES</h1>
                <ListBoxes>
                    <Box title="Devices" value={deviceInfo.Count.toString()}></Box>
                    <Box title="Patients" value={countsInfo.countPatients.Count.toString()}></Box>
                    <Box title="Exams" value={countsInfo.countExams.Count.toString()}></Box>
                    <BoxImg title="Add device" to="/devices/register"></BoxImg>
                </ListBoxes>
           
                <table className="table is-fullwidth is-hidden-touch is-narrow is-kv mt-6">
                    <thead>
                        <tr>
                            <th className="ta-l">DEVICE NAME</th>
                            <th className="ta-l">STATUS</th>  
                            <th></th>                          
                        </tr>
                    </thead>
                    <tbody>
                        {deviceInfo.Items.map((element) => (
                            <tr key={element.DEVICE_ID}>
                                <td>{element.DEVICE_ID}</td>
                                <td>{element.STATUS}</td>
                                <td className="ta-r">
                                    <button className="button is-danger is-small">Remove</button>
                                </td>
                            </tr>)
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}