import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserInfo, UserState, DeviceInfo} from '../../types'

const initialState: UserState = {
  userInfo: {},
  deviceInfo: {
      Count: 0,
      ScanCount: 0,
      Items: []
  }
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload;
    },
    setDevices(state, action: PayloadAction<DeviceInfo>) {      
      state.deviceInfo = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUserInfo, setDevices } = userSlice.actions

export default userSlice.reducer