import React from "react";

interface Props {
    onClick(): void
}

export default function Dashboard({ onClick } : Props) {

   
    return (
        <div className="App">
            <header className="App-header">              
                <button className="button is-primary" onClick={onClick}>
                    Login
                </button>
            </header>
        </div>
    )
}