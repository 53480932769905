import React from "react";

interface Props {
    children: React.ReactNode
}

/**
 * Draw List of Boxes
 */
export default function ListBoxes(props: Props): JSX.Element  {
    return (        
        <div className="columns mt-6">
           {props.children}
        </div>
    )
}