import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom'
import store from './app/store'
import { Provider } from 'react-redux'

console.log(process.env);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const redirectSignIn = process.env.REACT_APP_REDIRECT_SINGIN;
const redirectSignOut = process.env.REACT_APP_REDIRECT_SINGOUT;;

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'openid', 'phone'],
      redirectSignIn,
      redirectSignOut,
      responseType: 'code',
    },
  }
});

root.render(
  <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
