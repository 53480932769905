import { useEffect } from "react";
import { getExams } from "../../app/Api";
import { ExamsTable } from "../../types";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Header from "../Header";
import { Auth } from "aws-amplify";
import { ExamsReturnDataChart, ExamsReturnExams, ExamsReturnPatient } from "../../app/Util";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setExams } from "../features/PatientSlice";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'EXAMS',
            font: {
                size: 20
            }
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'DATE',
                font: {
                    weight: 'bold',
                }
            }
        },
        y: {
            title: {
                display: true,
                text: 'KV SCORE',
                font: {
                    weight: 'bold',
                }
            }
        }
    }
};


export default function ExamsContainer(): JSX.Element {
    const nav = useNavigate();
    const { pk, sk } = useParams()

    let patient = useAppSelector((state) => state.patients.Exams.Patient)
    let exams = useAppSelector((state) => state.patients.Exams.ExamsList)
    let dataChart = useAppSelector((state) => state.patients.Exams.ExamsChart)
    const dispatch = useAppDispatch();

    useEffect(() => {
        getExams(pk, sk).then((response: any) => {
            const data: ExamsTable = response.data;
            if (data.Count > 0) {                               
                dispatch(setExams({
                    Patient: ExamsReturnPatient(data),
                    ExamsList: ExamsReturnExams(data),
                    ExamsChart: ExamsReturnDataChart(data)
                }));                
            }
        }).catch(err => {
            if (
                'response' in err &&
                err.response.data.message === "The incoming token has expired"
            ) {
                Auth.signOut();
                nav('/');
            } else {
                console.log(err)
            }
        })
    }, [nav, pk, sk, dispatch]);


    return (
        <div className="columns">
            <Header />
            <div className="column px-6 pt-6 ">
                <h1>EXAMS</h1>
                <table className="table is-fullwidth is-hidden-touch is-narrow is-kv mt-6">
                    <thead>
                        <tr>
                            <th></th>
                            <th>NAME</th>
                            <th>DATE OF BIRTH</th>
                            <th>BMI</th>
                            <th>PAIN</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <span className="icon">
                                    {patient.gender === 'male' && (<svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.15058 1.59375C7.15058 2.47396 6.41147 3.1875 5.50048 3.1875C4.58948 3.1875 3.85037 2.47396 3.85037 1.59375C3.85037 0.713535 4.58948 0 5.50048 0C6.41147 0 7.15058 0.713535 7.15058 1.59375ZM5.22546 11.6875V15.9375C5.22546 16.5252 4.73387 17 4.12539 17C3.51691 17 3.02532 16.5252 3.02532 15.9375V8.52988L2.04317 10.1104C1.73033 10.6117 1.05448 10.7744 0.533667 10.4723C0.0128391 10.1701 -0.15563 9.51934 0.15734 9.01465L2.16074 5.79727C2.75718 4.8377 3.82975 4.25 4.98826 4.25H6.0127C7.17121 4.25 8.24377 4.8377 8.84194 5.79727L10.8427 9.01465C11.1555 9.51934 10.9871 10.1701 10.468 10.4723C9.94544 10.7744 9.27165 10.6117 8.95882 10.1104L7.97563 8.52988V15.9375C7.97563 16.5252 7.48404 17 6.87556 17C6.26709 17 5.77549 16.5252 5.77549 15.9375V11.6875H5.22546Z" fill="#10273E" />
                                    </svg>)}
                                    {patient.gender === 'female' && (<svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.85037 1.59375C3.85037 0.713535 4.58948 0 5.50048 0C6.41147 0 7.15058 0.713535 7.15058 1.59375C7.15058 2.47396 6.41147 3.1875 5.50048 3.1875C4.58948 3.1875 3.85037 2.47396 3.85037 1.59375ZM3.02532 12.75H2.41341C2.03801 12.75 1.77296 12.3947 1.89156 12.0494L3.20683 8.2377L2.04317 10.1104C1.73033 10.6117 1.05448 10.7744 0.533667 10.4723C0.0128391 10.1701 -0.15563 9.51934 0.15734 9.01465L2.0002 6.05293C2.69633 4.93398 3.95007 4.25 5.30109 4.25H5.69986C7.05089 4.25 8.30565 4.93398 9.00007 6.05293L10.8427 9.01465C11.1555 9.51934 10.9871 10.1701 10.468 10.4723C9.94544 10.7744 9.27165 10.6117 8.95882 10.1104L7.79343 8.2377L9.07914 12.0494C9.22696 12.3947 8.96225 12.75 8.58754 12.75H7.97563V15.9375C7.97563 16.5252 7.48404 17 6.87556 17C6.26709 17 5.77549 16.5252 5.77549 15.9375V12.75H5.22546V15.9375C5.22546 16.5252 4.73387 17 4.12539 17C3.51691 17 3.02532 16.5252 3.02532 15.9375V12.75Z" fill="#10273E" />
                                    </svg>)}
                                </span>
                            </td>
                            <td>
                                {patient.name}
                            </td>
                            <td>
                                {patient.date_birth}
                            </td>
                            <td>{patient.bmi}</td>
                            <td>{patient.pain}</td>
                        </tr>
                    </tbody>
                </table>

                <div className="mt-3 mb-3">
                    <Line options={options} data={dataChart}></Line>
                </div>

                <table className="table is-fullwidth is-hidden-touch is-narrow is-kv mt-6">
                    <thead>
                        <tr>
                            <th></th>
                            <th>KNEE</th>
                            <th>DATE REPORT</th>
                            <th>KV SCORE</th>
                            <th>PAIN</th>
                            <th>LISTEN</th>
                            <th>PRINT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {exams.map(e => (
                            <tr key={e["PATIENT_FULL_NAME#DATE_TIME"]}>
                                <td>
                                    <figure className="image is-16x16 is-inline-block mr-2">
                                        {e.ExamData.knee === 'right' && <span className="mdi mdi-arrow-right-bold"></span>}
                                        {e.ExamData.knee === 'left' && <span className="mdi mdi-arrow-left-bold"></span>}
                                    </figure>
                                </td>
                                <td>{e.ExamData.knee}</td>
                                <td>{e.ExamData.date}</td>
                                <td>{e.ExamData.score}</td>
                                <td>{e.ExamData.painScale}</td>
                                <td>
                                    <figure className="image is-16x16 is-inline-block mr-2">
                                        <span className="mdi mdi-play"></span>
                                    </figure>
                                </td>
                                <td>
                                    <NavLink to={`/exam/${encodeURIComponent(e["USER_ID#DEVICE_ID"])}/${encodeURIComponent(e["PATIENT_FULL_NAME#DATE_TIME"])}`}>
                                        <figure className="image is-16x16 is-inline-block mr-2">
                                            <span className="mdi mdi-printer"></span>
                                        </figure>
                                    </NavLink>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}