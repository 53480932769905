import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../comps/features/UserSlice';
import userCounts from '../comps/features/CountsSlice';
import patientReducer from '../comps/features/PatientSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    counts: userCounts,
    patients: patientReducer
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;