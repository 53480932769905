import React from "react";

interface Props {
  title: string
  value: string
}
/**
 * Draw Box  in List Boxes
 */
export default function Box(props: Props) : JSX.Element {
    return (
        <div className="column is-offset-1">
           <div className="box has-text-centered-desktop is-size-2-mobile is-flex-touch is-justify-content-space-between">
            {props.title}
            <h3 className="is-size-2 is-inline-block-mobile is-text-right-mobile">{props.value}</h3>
          </div>
        </div>
    )
}