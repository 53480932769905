import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CountsInfo, CountState } from '../../types'

const initialState: CountState = {
  countsInfo: {
    countExams: {
      Count: 0,
      ScanCount: 0
    },
    countPatients: {
      Count: 0,
      Items: []
    }
  }
}

export const countsSlice = createSlice({
  name: 'count',
  initialState,
  reducers: {
    setCounts(state, action: PayloadAction<CountsInfo>) {
      state.countsInfo = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const { setCounts } = countsSlice.actions

export default countsSlice.reducer