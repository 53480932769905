import { validateCodeRegister } from "../../app/Api";
import DeviceFormView from './DeviceFormView';



/**
 * Container Device info for register
 * @returns {String} JSX Template 
 */
export default function DeviceRegisterContainer() {

    const client_id = process.env.REACT_APP_COGNITO_CLIENT_ID; //pasar en redux
    const cognito_url = `${process.env.REACT_APP_COGNITO_PROTOCOL}://${process.env.REACT_APP_COGNITO_DOMAIN}/`;
    const uriLambdaCodeGen = process.env.REACT_APP_LAMBDA_GENERATE_CODE;
    const localRedirect = `${process.env.REACT_APP_LOCAL_REDIRECT}devices`;
    
    const authUrl = cognito_url + 'authorize' +
        '?response_type=code&scope=email+openid+phone&redirect_uri=' +
        `${uriLambdaCodeGen}&state=:deviceid&client_id=${client_id}` +
        `&identity_provider=COGNITO&code_challenge_method=S256&code_challenge=:codeChallenge`;
    
    const handleOnSubmit = (code:string) => {
        validateCodeRegister(code).then((response: any) => {
            if (response.data.statusCode === 200) {
                const deviceId = response.data.body.device_id; 
                const codeChallenge = response.data.body.code_challenge;
                const state = {
                    "device_id": deviceId,
                    "redirect": localRedirect
                }
                const newauth = authUrl.replace(':deviceid', encodeURI(btoa(JSON.stringify(state)))).replace(':codeChallenge', codeChallenge);
                alert(newauth);
                window.location.assign(newauth);
            } else {
                alert(response.data.body.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <DeviceFormView onSubmit={handleOnSubmit}></DeviceFormView>
    )
}
