import { useEffect } from "react";
import { getPatients } from "../../app/Api";
import { PatientType, PatientTableRow } from "../../types";
import { NavLink, useNavigate } from "react-router-dom";
import { setPatients } from "../features/PatientSlice";
import Header from "../Header";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Auth } from "aws-amplify";
import { calculateBMI } from "../../app/Util";


 

export default function PatientsContainer(): JSX.Element {
    const nav = useNavigate();
    let PatientTableData = useAppSelector((state) => {
        return state.patients.PatientTableData
    });
    const dispatch = useAppDispatch();

    const patientList = (data: PatientType) => {
        let tableData : Array<PatientTableRow>= [];
        let keyUsed : Array<String> = [];
        data.Items.forEach(e => {
            let bmi = calculateBMI( e.PATIENT_CORE_BODY.height.feet,e.PATIENT_CORE_BODY.height.inches, e.PATIENT_CORE_BODY.weight);
            let pk_split = e["USER_ID#DEVICE_ID"].split('#');
            let sk_split = e["PATIENT_FULL_NAME#DATE_TIME"].split('#');
            let obj: PatientTableRow = {
                pk: e["USER_ID#DEVICE_ID"],
                sk: sk_split[0],
                gender: e.PATIENT_INFORMATION.gender,
                name: e.PATIENT_INFORMATION.name[0].text,
                birth_date: e.PATIENT_INFORMATION.birthDate,
                bmi: bmi,
                height: e.PATIENT_CORE_BODY.height.feet.toString() + '\'' + parseInt(e.PATIENT_CORE_BODY.height.inches.toString()).toString() + '"',
                weight: e.PATIENT_CORE_BODY.weight.toString() + "lb",
                device: pk_split[1]
            }
            if (!keyUsed.includes(obj.sk)) {
                keyUsed.push(obj.sk);
                tableData.push(obj);
            }
        });
    
        return tableData;
    } 

    useEffect(() => {
        getPatients().then((response: any) => {
            const data: PatientType = response.data;
            const tableData = patientList(data);
            dispatch(setPatients({ data, tableData }));
        }).catch((err) => {
            if (
                'response' in err &&
                err.response.data.message === "The incoming token has expired"
            ) {
                Auth.signOut();
                nav('/');
            } else {
                console.log(err)
            }
        })
    }, [nav, dispatch]);

    //console.log(PatientTableData)
    return (
        <div className="columns">
            <Header />
            <div className="column px-6 pt-6 ">
                <h1>EXAMS</h1>
                <table className="table is-fullwidth is-hidden-touch is-narrow is-kv mt-6">
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <th>DATE OF BIRTH</th>
                            <th className="ta-c">SCORE LISTEN</th>
                            { /*<th className="ta-c">VIEW REPORT</th>*/ }
                            <th>DEVICE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            PatientTableData.map(e => (
                                <tr key={e.pk + e.sk}>
                                    <td><span className="icon">
                                        {e.gender === 'male' && (<svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.15058 1.59375C7.15058 2.47396 6.41147 3.1875 5.50048 3.1875C4.58948 3.1875 3.85037 2.47396 3.85037 1.59375C3.85037 0.713535 4.58948 0 5.50048 0C6.41147 0 7.15058 0.713535 7.15058 1.59375ZM5.22546 11.6875V15.9375C5.22546 16.5252 4.73387 17 4.12539 17C3.51691 17 3.02532 16.5252 3.02532 15.9375V8.52988L2.04317 10.1104C1.73033 10.6117 1.05448 10.7744 0.533667 10.4723C0.0128391 10.1701 -0.15563 9.51934 0.15734 9.01465L2.16074 5.79727C2.75718 4.8377 3.82975 4.25 4.98826 4.25H6.0127C7.17121 4.25 8.24377 4.8377 8.84194 5.79727L10.8427 9.01465C11.1555 9.51934 10.9871 10.1701 10.468 10.4723C9.94544 10.7744 9.27165 10.6117 8.95882 10.1104L7.97563 8.52988V15.9375C7.97563 16.5252 7.48404 17 6.87556 17C6.26709 17 5.77549 16.5252 5.77549 15.9375V11.6875H5.22546Z" fill="#10273E" />
                                        </svg>)}
                                        {e.gender === 'female' && (<svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.85037 1.59375C3.85037 0.713535 4.58948 0 5.50048 0C6.41147 0 7.15058 0.713535 7.15058 1.59375C7.15058 2.47396 6.41147 3.1875 5.50048 3.1875C4.58948 3.1875 3.85037 2.47396 3.85037 1.59375ZM3.02532 12.75H2.41341C2.03801 12.75 1.77296 12.3947 1.89156 12.0494L3.20683 8.2377L2.04317 10.1104C1.73033 10.6117 1.05448 10.7744 0.533667 10.4723C0.0128391 10.1701 -0.15563 9.51934 0.15734 9.01465L2.0002 6.05293C2.69633 4.93398 3.95007 4.25 5.30109 4.25H5.69986C7.05089 4.25 8.30565 4.93398 9.00007 6.05293L10.8427 9.01465C11.1555 9.51934 10.9871 10.1701 10.468 10.4723C9.94544 10.7744 9.27165 10.6117 8.95882 10.1104L7.79343 8.2377L9.07914 12.0494C9.22696 12.3947 8.96225 12.75 8.58754 12.75H7.97563V15.9375C7.97563 16.5252 7.48404 17 6.87556 17C6.26709 17 5.77549 16.5252 5.77549 15.9375V12.75H5.22546V15.9375C5.22546 16.5252 4.73387 17 4.12539 17C3.51691 17 3.02532 16.5252 3.02532 15.9375V12.75Z" fill="#10273E" />
                                        </svg>)}
                                    </span>{e.name}</td>
                                    <td>{e.birth_date}</td>
                                    <td className="ta-c">
                                        <NavLink to={`/patients/${encodeURIComponent(e.pk)}/${encodeURIComponent(e.sk)}`} >
                                        <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24.8489 7.69965C22.4952 3.1072 17.8355 0 12.5 0C7.16447 0 2.50345 3.10938 0.151018 7.70009C0.0517306 7.89649 0 8.11348 0 8.33355C0 8.55362 0.0517306 8.77061 0.151018 8.96701C2.50475 13.5595 7.16447 16.6667 12.5 16.6667C17.8355 16.6667 22.4965 13.5573 24.8489 8.96658C24.9482 8.77018 25 8.55319 25 8.33312C25 8.11304 24.9482 7.89605 24.8489 7.69965ZM12.5 14.5833C11.2638 14.5833 10.0555 14.2168 9.02766 13.53C7.99985 12.8433 7.19878 11.8671 6.72573 10.7251C6.25268 9.58307 6.12891 8.3264 6.37007 7.11402C6.61123 5.90164 7.20648 4.78799 8.08056 3.91392C8.95464 3.03984 10.0683 2.44458 11.2807 2.20343C12.493 1.96227 13.7497 2.08604 14.8917 2.55909C16.0338 3.03213 17.0099 3.83321 17.6967 4.86102C18.3834 5.88883 18.75 7.0972 18.75 8.33333C18.7504 9.15421 18.589 9.96711 18.275 10.7256C17.9611 11.484 17.5007 12.1732 16.9203 12.7536C16.3398 13.3341 15.6507 13.7944 14.8922 14.1084C14.1338 14.4223 13.3208 14.5837 12.5 14.5833ZM12.5 4.16667C12.1281 4.17186 11.7586 4.22719 11.4015 4.33116C11.6958 4.73119 11.8371 5.22347 11.7996 5.71873C11.7621 6.21398 11.5484 6.6794 11.1972 7.0306C10.846 7.38179 10.3806 7.5955 9.88537 7.63297C9.39012 7.67043 8.89784 7.52917 8.49781 7.23481C8.27001 8.07404 8.31113 8.96357 8.61538 9.77821C8.91962 10.5928 9.47167 11.2916 10.1938 11.776C10.916 12.2605 11.7719 12.5063 12.641 12.4788C13.5102 12.4514 14.3489 12.152 15.039 11.623C15.7291 11.0939 16.236 10.3617 16.4882 9.52951C16.7404 8.69729 16.7253 7.80693 16.445 6.98376C16.1647 6.16058 15.6333 5.44602 14.9256 4.94067C14.2179 4.43532 13.3696 4.16462 12.5 4.16667Z" fill="#0F334B" />
                                        </svg>
                                        </NavLink>
                                    </td>
                                    { /*<td className="ta-c">
                                          See Patient 
                                    </td> */ }
                                    <td>{e.device.substring(0, 10)}...</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}