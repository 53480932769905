import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";


/**
 * Return token auth
 * @returns string
 */
const getAuthorization =  (name_token: string): string => {
    const client_id = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const key = 'CognitoIdentityServiceProvider.' + client_id + '.' + getUserId() + "." + name_token;
    if (typeof window.localStorage.getItem(key) === 'undefined' || window.localStorage.getItem(key) === null) {
        return "";
    } else {
        return (window.localStorage.getItem(key) || '').toString();
    }
}
/**
 * Get the user id in local storage
 */
const getUserId = (): string | null => {
    const client_id = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const user_id = window.localStorage.getItem(`CognitoIdentityServiceProvider.${client_id}.LastAuthUser`);
    if (typeof user_id === 'undefined' || user_id === null) {
        return null;
    } else {
        return user_id;
    }
}

/**
 * Get the information of all devices.
*/
const getDevices = () => {
    const devicesUrl = process.env.REACT_APP_API_DEVICES;
    const user_id = getUserId();
    const auth = getAuthorization('idToken');   
  
    const headers: AxiosRequestHeaders = {
        Authorization : auth
    }
    const config: AxiosRequestConfig = {
        method: 'get',
        url: devicesUrl + '?sub=' + user_id,
        headers                  
    };
    console.log(config);
    return new Promise((resolve,  reject) => {
        axios(
            config
        ).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
};

/**
 * Get the information of counts and find patient.
*/
const getCounts = () => {
    const URL = process.env.REACT_APP_API_COUNTS;
    const user_id = getUserId();
    const auth = getAuthorization('idToken');   
  
    const headers: AxiosRequestHeaders = {
        Authorization : auth
    }
    const config: AxiosRequestConfig = {
        method: 'get',
        url: URL + '?sub=' + user_id,
        headers                  
    };
    //console.log(config);
    return new Promise((resolve,  reject) => {
        axios(
            config
        ).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
};

/**
 * Get the information  of user in cognito.
*/
const getUserInfo = () => {
    const cognito_url = `${process.env.REACT_APP_COGNITO_PROTOCOL}://${process.env.REACT_APP_COGNITO_DOMAIN}/`;
    const userInfoUrl = cognito_url + 'oauth2/userInfo';
    const token = getAuthorization('accessToken')

    const headers: AxiosRequestHeaders = {
        Authorization :'Bearer ' + token
    }
    const config: AxiosRequestConfig = {
        method: 'get',
        url: userInfoUrl,
        headers                  
    };

    return new Promise((resolve, reject) => {
        axios(config).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
};

/**
 * Register api call 
 * @param {Number} code  four digits code of register
 * @param {String} user_id cognito user id
 */
const validateCodeRegister = (code:string) => {
    const validateUrl = process.env.REACT_APP_API_VALIDATE_URL;
    const user_id = getUserId();

    const headers: AxiosRequestHeaders = {
        'Content-Type': "application/json"
    }

    const config: AxiosRequestConfig = {
        method: 'post',
        url: validateUrl,
        data: {
            "user_code": code,
            "user_id": user_id
        },
        headers                  
    };

    return new Promise((resolve, reject) => {
        axios(config).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
};

/**
 * Get list of patiens by user_id
 */
const getPatients = () => {
    const URL = process.env.REACT_APP_API_PATIENTS;
    const user_id = getUserId();
    const auth = getAuthorization('idToken');   
  
    const headers: AxiosRequestHeaders = {
        Authorization : auth
    }
    const config: AxiosRequestConfig = {
        method: 'get',
        url: URL + '?sub=' + user_id,
        headers                  
    };
    //console.log(config);
    return new Promise((resolve,  reject) => {
        axios(
            config
        ).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
}

/**
 * List of Exams in user_id and patient_id
 */
const getExams = (user_id: string|undefined, patient_id: string|undefined) => {
    if (user_id === undefined || patient_id === undefined) {
        return new Promise((resolve,reject) =>  { 
            reject("user_id or patient_id is undefined"); 
        });
    }

    const URL = process.env.REACT_APP_API_EXAMS;
   
    const auth = getAuthorization('idToken');   
  
    const headers: AxiosRequestHeaders = {
        Authorization : auth
    }
    const config: AxiosRequestConfig = {
        method: 'get',
        url: URL + '?sub=' + encodeURIComponent(user_id)+ '&full_name=' + encodeURIComponent(patient_id),
        headers                  
    };
    //console.log(config);
    return new Promise((resolve,  reject) => {
        axios(
            config
        ).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
};

export { getDevices, getUserInfo, validateCodeRegister, getUserId, getCounts, getPatients, getExams };