import React, { useState } from "react";
import { ReactComponent as Logo } from "../img/logo.svg";
import  MenuItem  from "./MenuItem";


/**
 *  Draw the menu of the page
 */
export default function Header() : JSX.Element {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <div className="column is-one-quarter-desktop bg-menu is-hidden-touch">
                <section id="left_menu" className="hero is-fullheight">
                    <aside className="menu">
                        <p className="menu-label mt-3 mb-5 ">
                            <Logo  className="mx-auto is-block"/>
                        </p>
                        <ul className="menu-list">
                            <MenuItem to="/devices"  icon="mdi-cellphone" title="Devices"/>
                            <MenuItem to="/patients/"  icon="mdi-chart-bar" title="Reports"/>                            
                            {/*<MenuItem to="/"  icon="mdi-clipboard-text-outline" title="Billing" />
                            <MenuItem to="/"  icon="mdi-cog" title="Support" />
                            <MenuItem to="/"  icon="mdi-account" title="Account" />*/}
                            <MenuItem to="/"  icon="mdi-logout-variant" title="Logout" />
                        </ul>
                    </aside>
                </section>
            </div><div className="column is-full is-hidden-desktop px-0">
                <section id="left_menu_mobile" className="is-full mt-0 pt-0 px-0">
                    <aside className="menu">
                        <p className="menu-label py-3 bg-menu has-text-centered t-w">                           
                            <Logo className="mx-auto is-inline-block" />                           
                            <button className="t-w" onClick={() => setOpen(!open)}>
                                <span className="mdi mdi-menu is-size-1"></span>
                            </button>
                        </p>
                        {open === true && 
                        <ul className="menu-list">
                            <MenuItem to="/devices/register"  icon="mdi-cellphone" title="Devices"/>
                            <MenuItem to="/"  icon="mdi-chart-bar" title="Reports"/>                            
                            <MenuItem to="/"  icon="mdi-clipboard-text-outline" title="Billing" />
                            <MenuItem to="/"  icon="mdi-cog" title="Support" />
                            <MenuItem to="/"  icon="mdi-account" title="Account" />
                            <MenuItem to="/"  icon="mdi-logout-variant" title="Logout" />
                        </ul>
                        }
                    </aside>
                </section>
            </div>
        </>
    )
}
