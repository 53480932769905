import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { getDevices, getUserId, getCounts } from "../app/Api";
import Header from './Header';
import { setUserInfo, setDevices } from "./features/UserSlice";
import { setCounts } from "./features/CountsSlice";
import ListBoxes from "./utils/ListBoxes";
import Box from "./utils/Box";
import { CountsInfo, DeviceInfo } from '../types'
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';


export default function Dashboard() {
    const dispatch = useAppDispatch();
    const deviceInfo = useAppSelector((state) => {      
      return state.user.deviceInfo
    });
    const countsInfo = useAppSelector((state) => {
        return state.counts.countsInfo
    }); 
    const nav = useNavigate();

    useEffect(() => {
        const sub = getUserId();
        dispatch(setUserInfo({ sub }));
        getDevices().then((response: any) => {    
            const data : DeviceInfo = response.data;       
            dispatch(setDevices(data));
        }).catch((err) => {
            if (err.response.data.message === "The incoming token has expired") {
                Auth.signOut();
                nav('/');
            } else {
                console.log(err)
            }
        });
        getCounts().then((response: any) => {
            const data  : CountsInfo = response.data;
            dispatch(setCounts(data));
        });
    }, [dispatch, nav]);

    /*const devices = devicesInfo.Count;
    console.log(devicesInfo)*/

    return (
        <div className="columns">
            <Header />
            <div className="column px-6 pt-6 ">
                <h1>DASHBOARD</h1>  
                <ListBoxes>
                    <Box title="Devices" value={deviceInfo.Count.toString()}></Box>    
                    <Box title="Patients" value={countsInfo.countPatients.Count.toString()}></Box>    
                    <Box title="Exams" value={countsInfo.countExams.Count.toString()}></Box>    
                </ListBoxes>  
            </div>
        </div>
    )
}
